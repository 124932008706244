import {HiCheckBadge} from '@react-icons/all-files/hi2/HiCheckBadge';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {LazyMotion, domAnimation, m} from 'framer-motion';
import {graphql, navigate} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React, {useContext} from 'react';
import Customer1 from '../assets/home/feedbacks/customer-review-avatar-1.png';
import Customer2 from '../assets/home/feedbacks/customer-review-avatar-2.png';
import Customer3 from '../assets/home/feedbacks/customer-review-avatar-3.png';
import Customer4 from '../assets/home/feedbacks/customer-review-avatar-4.png';
import Customer5 from '../assets/home/feedbacks/customer-review-avatar-5.png';
import Customer6 from '../assets/home/feedbacks/customer-review-avatar-6.png';
import PostCard from '../components/PostCard/PostCard';
import Seo from '../components/Seo/Seo';
import loadable from '@loadable/component';
import OneBitVentureImage from '../assets/home/partners/onebitVenture.png';
import BoxWrapper from '../components/Wrapper/BoxWrapper';
import CardWrapper from '../components/Wrapper/CardWrapper';
// import Orbit from '../components/_baseComponents/Animations/Orbit/Orbit';
// import TextFlip from '../components/_baseComponents/Animations/TextFlip/TextFlip';
import Button from '../components/_baseComponents/Button/Button';
import UniversalLink from '../components/_baseComponents/Link/UniversalLink';
import SectionHeading from '../components/_baseComponents/SectionHeading/SectionHeading';
import useCheckBreakpoint from '../hooks/useCheckBreakpoint';
import useGetLatestPosts from '../hooks/useGetLatestPosts';
import {PartnerImages} from '../staticData/PartnerImages';
import ThemeContext from '../store/theme-context';

const TextFlip = loadable(() =>
  import('../components/_baseComponents/Animations/TextFlip/TextFlip')
);
const Orbit = loadable(() =>
  import('../components/_baseComponents/Animations/Orbit/Orbit')
);
const Marquee = loadable(() =>
  import('../components/_baseComponents/Animations/Marquee/Marquee')
);
const Testimonial = loadable(() =>
  import('../components/Testimonial/Testimonial')
);

const heroTitles = [
  'Chuyển đổi số',
  'Nocode/Lowcode',
  'Thiết kế Website',
  'Lập trình WebApp',
  'Lập trình di động',
  'Lập trình Blockchain',
];

const IndexPage = () => {
  const {latestPosts} = useGetLatestPosts();
  const themeContext = useContext(ThemeContext);
  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();

  const partnerImages = PartnerImages;

  // Duplicate Element in Array
  const partnerImagesForEffect = [...partnerImages, ...partnerImages];

  // useEffect(() => {
  //   // themeContext.setNavTransparent(true);
  //   return () => {
  //     // themeContext.setNavTransparent(false);
  //   };
  // }, [themeContext]);

  const orbitBgRadius = {
    bigOrbit: 125,
    mediumOrbit: 100,
    smallOrbit: 75,
  };

  if (isDesktop) {
    orbitBgRadius.smallOrbit = 156;
    orbitBgRadius.mediumOrbit = 207;
    orbitBgRadius.bigOrbit = 256;
  } else if (isTablet) {
    orbitBgRadius.smallOrbit = 127;
    orbitBgRadius.mediumOrbit = 167;
    orbitBgRadius.bigOrbit = 207;
  }

  const orbitBgs = [
    {
      config: {
        className:
          'h-[250px] w-[250px] md:h-[414px] md:w-[414px] lg:h-[512px] lg:w-[512px] border border border-gray-400/10 lg:-top-12 -top-6 md:top-0',
        radius: orbitBgRadius.bigOrbit,
        duration: 75,
      },
      feedbacks: [
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-purple-400/10 p-2">
                <div className="h-4 w-4 rounded-full bg-purple-400" />
              </div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-yellow-400/10 p-2">
                <div className="h-4 w-4 rounded-full bg-yellow-400" />
              </div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 16,
            width: 16,
            Item: () => (
              <div className="h-4 w-4 rounded-full bg-green-400/10 p-1">
                <div className="h-2 w-2 rounded-full bg-green-400" />
              </div>
            ),
          },
        },
      ],
    },
    {
      config: {
        className:
          'h-[200px] w-[200px] md:h-[334px] md:w-[334px] lg:h-[414px] lg:w-[414px] border border-gray-400/10 top-0 md:top-10 lg:top-0',
        radius: orbitBgRadius.mediumOrbit,
        isReverse: true,
        duration: 50,
      },
      feedbacks: [
        {
          thumbnail: {
            type: 0,
            height: 16,
            width: 16,
            Item: () => (
              <div className="h-4 w-4 rounded-full bg-cyan-400/10 p-1">
                <div className="h-2 w-2 rounded-full bg-cyan-400" />
              </div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-rose-400/10 p-2">
                <div className="h-4 w-4 rounded-full bg-rose-400" />
              </div>
            ),
          },
        },
      ],
    },

    {
      config: {
        className:
          'h-[150px] w-[150px] md:w-[254px] md:h-[254px] lg:h-[312px] lg:w-[312px] border border-gray-400/20 lg:top-14 top-6 md:top-20',
        radius: orbitBgRadius.smallOrbit,
        duration: 35,
      },
      feedbacks: [
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-primary/10 p-2">
                <div className="h-4 w-4 rounded-full bg-primary" />
              </div>
            ),
          },
        },
      ],
    },
  ];

  const orbitFeedbackRadius = {
    first: {
      imageSize: 56,
      radius: 160,
    },
    second: {
      imageSize: 56,
      radius: 110,
    },
    third: {
      imageSize: 56,
      radius: 60,
    },
    fourth: {
      imageSize: 32,
      radius: 0,
    },
  };

  if (isDesktop) {
    orbitFeedbackRadius.first.radius = 300;
    orbitFeedbackRadius.first.imageSize = 64;

    orbitFeedbackRadius.second.radius = 225;
    orbitFeedbackRadius.second.imageSize = 64;

    orbitFeedbackRadius.third.radius = 150;
    orbitFeedbackRadius.third.imageSize = 64;

    orbitFeedbackRadius.fourth.radius = 70;
    orbitFeedbackRadius.fourth.imageSize = 64;
  }
  if (isTablet) {
    orbitFeedbackRadius.first.radius = 210;
    orbitFeedbackRadius.first.imageSize = 64;

    orbitFeedbackRadius.second.radius = 160;
    orbitFeedbackRadius.second.imageSize = 64;

    orbitFeedbackRadius.third.radius = 110;
    orbitFeedbackRadius.third.imageSize = 64;

    orbitFeedbackRadius.fourth.radius = 60;
    orbitFeedbackRadius.fourth.imageSize = 64;
  }
  const feedbacks = [
    {
      config: {
        className:
          'h-[320px] w-[320px] border-2 border-dashed border-primary md:h-[420px] md:w-[420px] lg:h-[600px] lg:w-[600px]',
        radius: orbitFeedbackRadius.first.radius,
        duration: 70,
      },
      feedbacks: [
        {
          name: 'Võ Ngọc Hân',
          jobTitle: 'Chủ shop HanVN',
          content:
            'Công ty công nghệ hàng đầu Việt Nam, mọi thứ đều quá ổn. Tôi nhận được sự trợ giúp tức thì khi cần. Luôn luôn tin tưởng vào các giải pháp của KDIGI.',
          imageSrc: Customer1,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.first.imageSize,
            width: orbitFeedbackRadius.first.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-1.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12 lg:h-16 lg:w-16 "
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-primary/10 p-2">
                <div className="h-4 w-4 rounded-full bg-primary" />
              </div>
            ),
          },
        },
        {
          name: 'Nguyễn Thanh Trường',
          jobTitle: 'Giám đốc công ty TruongNT',
          content:
            'Thật vui khi được hợp tác với KDIGI để chuyển đổi số cho công ty của tôi',
          imageSrc: Customer2,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.first.imageSize,
            width: orbitFeedbackRadius.first.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-2.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12 lg:h-16 lg:w-16"
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 32,
            width: 32,
            Item: () => (
              <div className="h-8 w-8 rounded-full bg-primary/10 p-2">
                <div className="h-4 w-4 rounded-full bg-primary" />
              </div>
            ),
          },
        },
      ],
    },
    {
      config: {
        className:
          'left-[50px] top-[50px] h-[220px] w-[220px] border-2 border-primary md:h-[320px] md:w-[320px] lg:top-[75px] lg:left-[75px] lg:h-[450px] lg:w-[450px]',
        radius: orbitFeedbackRadius.second.radius,
        isReverse: true,
        duration: 50,
      },
      feedbacks: [
        {
          name: 'Phan Tuấn Khải',
          jobTitle: 'CTO công ty TuanPK',
          content:
            'Với sự tư vấn tận tình của KDIGI, tôi đã chuyển đổi số thành công cho công ty của mình',
          imageSrc: Customer3,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.second.imageSize,
            width: orbitFeedbackRadius.second.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-3.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12 lg:h-16 lg:w-16"
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          name: 'Nguyễn Tiên',
          jobTitle: 'Phó giám đốc công ty TT',
          content: 'Phần mềm của công ty KDIGI rất tốt, chạy mượt mà.',
          imageSrc: Customer4,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.second.imageSize,
            width: orbitFeedbackRadius.second.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-4.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12 lg:h-16 lg:w-16"
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 16,
            width: 16,
            Item: () => (
              <div className="h-4 w-4 rounded-full bg-primary/10 p-1">
                <div className="h-2 w-2 rounded-full bg-primary" />
              </div>
            ),
          },
        },
      ],
    },
    {
      config: {
        className:
          'top-[100px] left-[100px] h-[120px] w-[120px] border-2 border-dashed border-primary md:h-[220px] md:w-[220px] lg:top-[150px] lg:left-[150px] lg:h-[300px] lg:w-[300px]',
        radius: orbitFeedbackRadius.third.radius,
        duration: 55,
      },
      feedbacks: [
        {
          name: 'Mrs.Kate',
          jobTitle: 'Chủ cửa hàng Kate',
          content:
            'KDIGI có dịch vụ thiết kế website rất tuyệt vời, thiết kế rất đẹp, khả năng SEO rất tốt.',
          imageSrc: Customer5,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.third.imageSize,
            width: orbitFeedbackRadius.third.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-5.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12 lg:h-16 lg:w-16"
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 16,
            width: 16,
            Item: () => (
              <div className="h-4 w-4 rounded-full bg-primary/10 p-1">
                <div className="h-2 w-2 rounded-full bg-primary" />
              </div>
            ),
          },
        },
      ],
    },
    {
      config: {
        className:
          'top-[150px] left-[150px] hidden h-[120px] w-[120px] border-2 border-primary md:block lg:top-[230px] lg:left-[230px] lg:h-[140px] lg:w-[140px]',
        radius: orbitFeedbackRadius.fourth.radius,
        isReverse: true,
        duration: 65,
      },
      feedbacks: [
        {
          name: 'Ms.Hoa',
          jobTitle: 'Giám đốc tập đoàn Hoa',
          content:
            'Tôi đã ký hợp đồng triển khai chuyển đổi số với KDIGI cho tập đoàn của mình. Sau 1 năm làm việc, tôi rất hài lòng với kết quả đã đạt được',
          imageSrc: Customer6,
          thumbnail: {
            type: 1,
            height: orbitFeedbackRadius.fourth.imageSize,
            width: orbitFeedbackRadius.fourth.imageSize,
            Item: () => (
              <m.div
                className="flex items-center justify-center rounded-full hover:bg-primary/20"
                whileHover={{
                  scale: 1.25,
                }}
              >
                <StaticImage
                  src="../assets/home/feedbacks/customer-review-avatar-6.png"
                  alt="Customer avatar"
                  width={128}
                  height={128}
                  className="h-12 w-12  lg:h-16 lg:w-16"
                  imgClassName="rounded-full"
                />
              </m.div>
            ),
          },
        },
        {
          thumbnail: {
            type: 0,
            height: 8,
            width: 8,
            Item: () => (
              <div className="h-2 w-2 rounded-full bg-primary/10 p-0.5">
                <div className="h-1 w-1 rounded-full bg-primary" />
              </div>
            ),
          },
        },
      ],
    },
  ];

  return (
    <LazyMotion features={domAnimation} strict>
      <div className="flex flex-col gap-12 bg-transparent pt-[70px] md:gap-16 lg:flex-none lg:gap-12 lg:pt-20">
        {/* Hero section */}
        <BoxWrapper className={'flex-col pt-10 md:gap-0 md:pt-6 lg:pt-20'}>
          {/* Bg effect */}
          <div className="relative before:absolute before:-right-[15vw] before:-top-[15vw] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-red-600/30 before:blur-[6rem] before:will-change-[filter] before:md:blur-[13.5rem] before:lg:bg-red-400/10 before:lg:blur-[5rem]" />

          <div className="flex flex-col-reverse gap-10 lg:flex-row lg:gap-12">
            {/* Introduction */}
            <div className="flex w-full flex-col gap-5 lg:w-[40%]">
              <div className="flex flex-col gap-3 text-3xl font-bolder md:text-4xl lg:gap-5 lg:text-5xl lg:font-bold">
                <span>Giải pháp</span>
                <TextFlip
                  items={heroTitles}
                  timeout={3000}
                  className="h-10 text-primary lg:h-14"
                />
                <span className="-mt-2 md:mt-0 lg:-mt-4 lg:leading-[4rem]">
                  cho doanh nghiệp
                </span>
              </div>
              <p className="text-justify">
                KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số
                cho doanh nghiệp tại Việt Nam. Chúng tôi tư vấn và triển khai
                phần mềm doanh nghiệp, giải pháp all-in-one website, automation
                nocode/lowcode giúp doanh nghiệp hoạt động hiệu quả trong môi
                trường số.
              </p>
              <div className="flex flex-col items-start gap-4 md:flex-row lg:flex-row">
                <Button
                  type="primary"
                  content="Dịch vụ của chúng tôi"
                  Icon={ButtonIcon}
                  className="flex justify-center gap-2 px-6 py-3 font-medium md:flex-none lg:flex-none"
                  onClick={() => navigate('/dich-vu')}
                />
                <Button
                  type="outline"
                  content="Tư vấn miễn phí"
                  Icon={ButtonIcon}
                  className="flex justify-center gap-2 px-6 py-3 font-medium md:flex-none lg:flex-none"
                  onClick={() => navigate('/lien-he')}
                />
              </div>
            </div>

            {/* Image */}
            <div className="relative flex flex-1 flex-col items-center">
              {/* Orbit Background */}
              {orbitBgs.map((feedbackObj, i) => {
                const inputFeedback = feedbackObj.feedbacks.map((feedback) => {
                  return {
                    ...feedback.thumbnail,
                  };
                });
                return (
                  <Orbit
                    key={i}
                    items={inputFeedback}
                    {...feedbackObj.config}
                  />
                );
              })}

              <StaticImage
                src="../assets/home/home-hero.png"
                alt="Home Thumbnail"
                width={600}
                className="w-[320px] md:w-[500px] lg:-mt-20 lg:w-[600px]"
                imgClassName=""
                objectFit="cover"
              />
            </div>
          </div>
          {/* Bg effect */}
          <div className="relative before:absolute before:-left-[10vw] before:-top-[36rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-blue-600/40 before:blur-[6rem] before:will-change-[filter] before:md:-top-[28rem] before:md:blur-[13.5rem] before:lg:-bottom-[30vw] before:lg:bg-blue-600/10 before:lg:blur-[8rem]" />

          {/* Specs */}
          <div className="mt-10 flex w-full flex-row flex-wrap justify-center gap-3 md:mt-20 md:flex-nowrap md:gap-5 lg:h-48">
            <CardWrapper
              isShadow={false}
              isBlur={false}
              className={
                ' flex w-[45%] flex-col items-center justify-center bg-white/30 py-8 lg:flex-1 lg:px-14'
              }
              roundedClass="rounded-lg"
            >
              <span className="text-3xl font-bolder text-primary lg:text-4xl lg:font-bold">
                100+
              </span>
              <span className="text-sm font-normal text-slate-400 lg:text-xl lg:font-medium">
                Sản phẩm
              </span>
            </CardWrapper>

            <CardWrapper
              isShadow={false}
              isBlur
              className={
                ' flex w-[45%] flex-col items-center justify-center bg-white/30 py-8 lg:flex-1 lg:px-14'
              }
              roundedClass="rounded-lg"
            >
              <span className="text-3xl font-bolder text-primary lg:text-4xl lg:font-bold">
                20
              </span>
              <span className="text-sm font-normal text-slate-400 lg:text-xl lg:font-medium">
                Dịch vụ
              </span>
            </CardWrapper>

            <CardWrapper
              isShadow={false}
              isBlur
              className={
                'flex w-[45%] flex-col items-center justify-center bg-white/30 py-8 lg:flex-1 lg:px-14'
              }
              roundedClass="rounded-lg"
            >
              <span className="text-3xl font-bolder text-primary lg:text-4xl lg:font-bold">
                1000+
              </span>
              <span className="text-sm font-normal text-slate-400 lg:text-xl lg:font-medium">
                Khách hàng
              </span>
            </CardWrapper>

            <CardWrapper
              isShadow={false}
              isBlur={false}
              className={
                ' flex w-[45%] flex-col items-center justify-center bg-white/30 py-8 lg:flex-1 lg:px-14'
              }
              roundedClass="rounded-lg"
            >
              <span className="text-3xl font-bolder text-primary lg:text-4xl lg:font-bold">
                800+
              </span>
              <span className="text-sm font-normal text-slate-400 lg:text-xl lg:font-medium">
                Phản hồi tích cực
              </span>
              {/* Bg effect */}
              <div className="relative before:absolute  before:-right-[20vw] before:-top-[18rem] before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-orange-500/40 before:blur-[7.5rem] before:will-change-[filter] before:md:top-[10vw] before:md:bg-orange-400/20 before:md:blur-[13.5rem] before:lg:top-[10vw] before:lg:bg-orange-400/10 before:lg:blur-[5rem]" />
            </CardWrapper>
          </div>
        </BoxWrapper>

        {/* Balloon motion */}
        <m.div
          className={`relative left-[84%] top-28 hidden before:absolute before:h-14 before:w-14 before:rounded-full before:bg-gradient-to-tl before:from-cyan-300/70 before:to-white/70 before:shadow-lg before:shadow-cyan-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -80, 0]}}
          transition={{
            duration: 10,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />

        {/* Services */}
        <BoxWrapper className={'flex-col gap-16'}>
          {/* Heading */}
          <SectionHeading
            content="Dịch vụ"
            subContent=" KDIGI là công ty công nghệ cung cấp các giải pháp chuyển đổi số cho
            doanh nghiệp tại Việt Nam."
            className="w-full items-center"
            subContentClassName="max-w-lg text-center"
          />

          {/* Solutions */}
          <div className="w-full overflow-auto lg:overflow-visible">
            <div className="mt-14 flex w-[56rem] flex-row  gap-4 md:w-full md:justify-center lg:w-full lg:justify-between lg:gap-8">
              {/* Business Solution */}
              <CardWrapper
                isBlur
                isShadow={false}
                roundedClass={'rounded-2xl'}
                className={
                  'flex w-72 flex-col items-center gap-6 bg-white/30 px-4 py-12 lg:w-auto lg:px-16'
                }
              >
                <StaticImage
                  src="../assets/home/solution-computer.png"
                  alt="Business Solution"
                  width={144}
                  height={144}
                  className="-mt-28 h-24 w-24 lg:-mt-32 lg:h-36 lg:w-36"
                />
                <div className="flex flex-col gap-6">
                  <h3 className="w-full text-left text-xl font-bold md:text-base lg:text-xl ">
                    Giải pháp doanh nghiệp
                  </h3>
                  <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                    <div className="flex flex-row items-center gap-2 ">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>
                        Giải pháp Digital Workdplace
                      </UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Giải pháp ERP</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Giải pháp CRM</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Microsoft 365</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Google Workplace</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Lark</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Dịch vụ SEO</UniversalLink>
                    </div>
                  </div>
                </div>
              </CardWrapper>

              {/* Programming Solution */}
              <CardWrapper
                isBlur
                isShadow={false}
                roundedClass={'rounded-2xl'}
                className={
                  'flex w-72 flex-col items-center gap-6 bg-white/30 px-4 py-12 lg:w-auto lg:px-16'
                }
              >
                <StaticImage
                  src="../assets/home/solution-cogs.png"
                  alt="Programming Solution"
                  width={144}
                  height={144}
                  className="-mt-28 h-24 w-24 lg:-mt-32 lg:h-36 lg:w-36"
                />
                <div className="flex flex-col gap-6">
                  <h3 className="w-full text-left text-xl font-bold md:text-base lg:text-xl">
                    Giải pháp lập trình
                  </h3>
                  <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Thiết kế Website</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Lập trình WebApp</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>
                        Lập trình Ứng dụng Di động
                      </UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Blockchain & Web3</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>
                        Phần mềm theo yêu cầu
                      </UniversalLink>
                    </div>
                  </div>
                </div>
              </CardWrapper>

              {/* Domain Solution */}
              <CardWrapper
                isBlur
                isShadow={false}
                roundedClass={'rounded-2xl'}
                className={
                  'flex w-72 flex-col items-center gap-6 bg-white/30 px-4 py-12 lg:w-auto lg:px-16'
                }
              >
                <StaticImage
                  src="../assets/home/solution-cloud.png"
                  alt="Domain - Hosting - Cloud"
                  width={144}
                  height={144}
                  className="-mt-28 h-24 w-24 lg:-mt-32 lg:h-36 lg:w-36"
                />
                <div className="flex flex-col gap-6">
                  <h3 className="w-full text-left text-xl font-bold md:text-base lg:text-xl">
                    Tên miền - Hosting - Cloud
                  </h3>
                  <div className="flex w-full flex-col items-start gap-4 md:text-sm lg:text-base">
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Dịch vụ tên miền</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Dịch vụ Hosting</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Dịch vụ Cloud</UniversalLink>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <HiCheckBadge className="h-4 w-4 flex-none fill-primary lg:h-5 lg:w-5" />
                      <UniversalLink to={'/'}>Dịch vụ DevOps</UniversalLink>
                    </div>
                  </div>
                </div>
              </CardWrapper>
            </div>
          </div>
        </BoxWrapper>
        {/* Balloon motion */}
        <m.div
          className={`relative left-[10%] top-8 hidden before:absolute before:h-28 before:w-28 before:rounded-full before:bg-gradient-to-tl before:from-blue-300/70 before:to-white/70 before:shadow-lg before:shadow-blue-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -60, 0]}}
          transition={{
            duration: 10,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />
        {/* Balloon motion */}
        <m.div
          className={`relative left-[16%] top-12 hidden before:absolute before:h-14 before:w-14 before:rounded-full before:bg-gradient-to-tl before:from-blue-300/70 before:to-white/70 before:shadow-lg before:shadow-blue-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -80, 0]}}
          transition={{
            duration: 8,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />

        {/* Projects */}
        <BoxWrapper className={'flex-col items-center gap-8 lg:gap-4'}>
          {/* Heading */}
          <SectionHeading content="Dự án" />

          {/* List Project */}
          <div className="flex flex-col gap-10 md:gap-8 lg:flex-none">
            {/* Project Inhouse */}
            <div className="flex w-full flex-col items-center justify-center  md:flex-row md:gap-8 lg:gap-5 xl:gap-10">
              <StaticImage
                src="../assets/home/project-thumbup-man.png"
                alt="Project Inhouse"
                width={500}
                className="w-[320px] flex-none md:w-[300px] lg:w-[500px]"
              />
              <div className="">
                {/* Bg effect */}
                <div className="relative before:absolute before:-top-[52rem] before:right-[10vw] before:-z-10 before:block before:h-[55vw] before:w-[55vw] before:rounded-full before:bg-green-500/30 before:blur-[7.5rem] before:will-change-[filter] before:md:-top-[30rem] before:md:bg-green-500/20 before:md:blur-[15rem]  before:lg:-left-[30vw] before:lg:top-0  before:lg:bg-lime-300/10 before:lg:blur-[8rem]" />

                {/* Bg effect for mobile */}
                <div className="relative hidden before:absolute before:right-[20vw] before:top-[20rem] before:-z-10 before:block before:h-[55vw] before:w-[55vw] before:rounded-full before:bg-orange-500/40 before:blur-[7.5rem] before:will-change-[filter] md:flex lg:hidden before:lg:-left-[30vw]" />
              </div>
              <div className="flex flex-col gap-5  lg:relative lg:-left-4 lg:w-[520px] lg:items-start">
                <h3 className="text-xl font-bolder lg:text-2xl">
                  Dự án Inhouse
                </h3>
                <p className="text-base text-gray-500">
                  Dẫn đầu về tư vấn giải pháp chuyển đổi số (Digital
                  Transformation) cho tổ chức, doanh nghiệp. Dẫn đầu về cung cấp
                  giải pháp an ninh bảo mật và điện toán đám mây và dịch vụ dữ
                  liệu.
                </p>
                <Button
                  type="primary"
                  content="Xem thêm"
                  Icon={ButtonIcon}
                  className="w-fit gap-2 px-6 py-2 font-medium"
                  onClick={() => navigate('/du-an/du-an-inhouse')}
                />
              </div>
            </div>

            {/* Project Outsource */}
            <div className="flex w-full flex-col-reverse items-center justify-center md:flex-row md:gap-14 lg:gap-20 ">
              <div className="flex flex-col items-end gap-5 md:items-start lg:w-[520px]">
                <h3 className="text-xl font-bolder lg:text-2xl">
                  Dự án Outsource
                </h3>
                <p className="text-right text-base text-gray-500 md:text-left">
                  Dẫn đầu về tư vấn giải pháp chuyển đổi số (Digital
                  Transformation) cho tổ chức, doanh nghiệp. Dẫn đầu về cung cấp
                  giải pháp an ninh bảo mật và điện toán đám mây và dịch vụ dữ
                  liệu.
                </p>
                <Button
                  type="primary"
                  content="Xem thêm"
                  Icon={ButtonIcon}
                  className="w-fit gap-2 px-6 py-2 font-medium"
                  onClick={() => navigate('/du-an/du-an-outsource')}
                />
              </div>
              <StaticImage
                src="../assets/home/project-checkphone-man.png"
                alt="Project Outsource"
                width={500}
                className="w-[320px] flex-none md:w-[300px] lg:w-[500px]"
              />
            </div>

            {/* Project Consultant */}
            <div className="flex w-full flex-col items-center justify-center md:flex-row md:gap-7 lg:gap-5 xl:gap-10">
              <StaticImage
                src="../assets/home/project-presentation-man.png"
                alt="Project Consultant"
                width={500}
                className="w-[320px] flex-none md:w-[300px] lg:w-[500px]"
              />
              {/* Bg effect */}
              <div className="relative  before:absolute before:-top-[115rem] before:left-0 before:-z-10 before:block before:h-[50vw] before:w-[50vw] before:rounded-full before:bg-cyan-400/50 before:blur-[7.5rem] before:will-change-[filter] before:md:-top-[15rem] before:md:bg-cyan-400/20 before:md:blur-[15rem] before:lg:-right-[15vw] before:lg:-top-[30vw] before:lg:bg-cyan-300/10 before:lg:blur-[8rem]" />

              <div className="flex flex-col gap-5 lg:relative lg:-left-4 lg:w-[520px]">
                <h3 className="text-xl font-bolder lg:text-2xl">
                  Dự án Consultant
                </h3>
                <p className="text-base text-gray-500">
                  Dẫn đầu về tư vấn giải pháp chuyển đổi số (Digital
                  Transformation) cho tổ chức, doanh nghiệp. Dẫn đầu về cung cấp
                  giải pháp an ninh bảo mật và điện toán đám mây và dịch vụ dữ
                  liệu.
                </p>
                <Button
                  type="primary"
                  content="Xem thêm"
                  Icon={ButtonIcon}
                  className="w-fit gap-2 px-6 py-2 font-medium"
                  onClick={() => navigate('/du-an/du-an-consultant')}
                />
              </div>
            </div>
          </div>
        </BoxWrapper>
        {/* Balloon motion */}
        <m.div
          className={`relative left-[80%] hidden before:absolute before:h-28 before:w-28 before:rounded-full before:bg-gradient-to-tl before:from-blue-300/70 before:to-white/70 before:shadow-lg before:shadow-blue-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -60, 0], rotate: [0, 5, 0]}}
          transition={{
            duration: 10,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />
        {/* Balloon motion */}
        <m.div
          className={`relative left-[86%] hidden before:absolute before:h-16 before:w-16 before:rounded-full before:bg-gradient-to-tl before:from-cyan-300/70 before:to-white/70 before:shadow-lg before:shadow-cyan-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -80, 0]}}
          transition={{
            duration: 8,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />

        {/* Customer Review */}
        <BoxWrapper>
          <Testimonial
            feedbackList={feedbacks}
            className="w-full flex-col items-center justify-center gap-5 md:flex-row lg:grid lg:h-[600px] lg:w-full lg:grid-cols-2 xl:gap-20"
            orbitClassName="lg:h-[600px] lg:w-[600px] h-[320px] w-[320px] md:h-[420px] md:w-[420px]"
            contentClassName="flex-1 gap-10 xl:max-w-[512px] lg:max-w-[406x] lg:h-[300px] md:max-w-[300px] md:-right-5 md:relative xl:position-none"
          />
        </BoxWrapper>

        {/* Balloon motion */}
        <m.div
          className={`relative left-[13%] top-8 hidden before:absolute before:h-28 before:w-28 before:rounded-full before:bg-gradient-to-tl before:from-cyan-300/70 before:to-white/70 before:shadow-lg before:shadow-cyan-300/70 before:backdrop-blur-lg lg:flex`}
          initial={{y: 0}}
          animate={{y: [0, -80, 0]}}
          transition={{
            duration: 10,
            bounce: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />

        {/* Blog */}
        {latestPosts?.nodes?.length > 0 && (
          <BoxWrapper
            className={'w-full flex-col items-center gap-8 lg:gap-10'}
          >
            <SectionHeading
              content="Blog"
              className="w-full items-center"
              subContent="Cập nhật những tin tức công nghệ mới nhất"
            />
            <div className="scrollbar-hide scrollbar-hover-show flex w-full flex-col items-center justify-center gap-4 py-5 md:grid md:auto-cols-[300px] md:grid-flow-col md:grid-cols-[300px] md:items-baseline md:justify-normal md:gap-8 md:overflow-auto">
              {latestPosts.nodes.slice(0, 8).map((post) => (
                <PostCard
                  key={post.id}
                  post={post}
                  className={'w-[300px]'}
                  imgClassName={'w-[300px] h-[150px]'}
                />
              ))}
            </div>
            <Button
              type="primary"
              content="Xem tất cả"
              Icon={ButtonIcon}
              className="w-fit gap-2 px-6 py-2 font-medium"
              onClick={() => navigate('/blog')}
            />
          </BoxWrapper>
        )}
        {/* Partner */}
        <BoxWrapper
          className={'w-full flex-col items-center gap-8 md:gap-10 lg:gap-5'}
        >
          {/* Heading */}
          <SectionHeading
            content="Đối tác & Khách hàng"
            className="w-full items-center"
          />

          <section className="bg-section-partner 0 relative -left-5 m-auto mt-10 w-[960px] overflow-hidden bg-gray-400/10 py-3 md:-left-20 md:mt-0 lg:left-0 lg:h-[400px] lg:bg-white">
            {/* <SuspenseHelper fallback={<div />}>
          </SuspenseHelper> */}
            <Marquee items={partnerImagesForEffect} speed={25} />
            <Marquee items={partnerImagesForEffect} speed={30} />

            {/* Show on desktop  */}
            <div
              className="absolute left-[20vw] hidden flex-col items-center justify-center gap-3 rounded-lg bg-white px-3 pb-3 pt-6 lg:bottom-24 lg:left-[24%] lg:flex lg:w-[32rem] lg:flex-row lg:gap-5 lg:rounded-xl lg:p-0"
              style={{boxShadow: '8px 8px 16px 2px rgba(213, 226, 233, 0.25)'}}
            >
              <div className="w-28 lg:w-64">
                <img
                  src={OneBitVentureImage}
                  height={'100px'}
                  width={'250px'}
                  alt=""
                />
              </div>

              <div className="">
                <Button
                  type="primary"
                  content="Xem Case Study"
                  Icon={ButtonIcon}
                  className="w-fit gap-2 px-6 py-2 font-medium"
                />
              </div>
            </div>
          </section>
          {/* Hide on desktop */}
          <div
            className="relative -top-14 flex flex-col items-center justify-center gap-3 rounded-lg bg-white p-3 md:-top-20 md:flex-row md:px-6 lg:hidden"
            style={{boxShadow: '8px 8px 16px 2px rgba(213, 226, 233, 0.25)'}}
          >
            <div className="w-28 md:w-48">
              <img
                src={OneBitVentureImage}
                height={'100px'}
                width={'250px'}
                alt=""
              />
            </div>

            <div className="">
              <Button
                type="primary"
                content="Xem Case Study"
                Icon={ButtonIcon}
                className="w-fit gap-2 px-6 py-2 font-medium"
              />
            </div>
          </div>
        </BoxWrapper>
      </div>
    </LazyMotion>
  );
};

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;

export default IndexPage;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(isFrontPage: {eq: true}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;

import React, {Fragment} from 'react';
import UniversalLink from '../_baseComponents/Link/UniversalLink';

/**
 * Render list of categories for a post
 * @param {Object} props
 * @param {Object} props.categories List categories of post
 * @param {String} props.textSize Tailwind text size
 * @returns
 */
const PostCategories = ({categories, textSize = 'text-sm'}) => {
  return (
    <>
      {categories && (
        <div>
          {/* From 1 to n-1 items */}
          {categories.slice(0, -1).map((category) => (
            <Fragment key={category.id}>
              <UniversalLink
                to={category.uri}
                className={`${textSize} hover:text-blue-700`}
              >
                <span>{category.name}</span>
              </UniversalLink>
              <span className="mr-1 last:mr-0">,</span>
            </Fragment>
          ))}

          {/* Last item */}
          <UniversalLink
            to={categories.at(-1).uri}
            className={`${textSize} hover:text-blue-700`}
          >
            <span>{categories.at(-1).name}</span>
          </UniversalLink>
        </div>
      )}
    </>
  );
};

export default PostCategories;

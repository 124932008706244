import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import React, {useState} from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import PostCategories from '../PostCategories/PostCategories';
import PostTags from '../PostTags/PostTags';
import CardWrapper from '../Wrapper/CardWrapper';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import {TbCalendarEvent} from '@react-icons/all-files/tb/TbCalendarEvent';
/**
 *
 * @param {Object} props - React props
 * @param {Object} props.post - post object
 * @param {Object} props.className - Class name of Post Card wrapper
 * @param {Object} props.imgClassName - Class names of Post thumbnail
 * @returns
 */
function PostCard({post, className = '', imgClassName = ''}) {
  const [isShadow, setIsShadow] = useState(false);

  const renderThumbnail = post.featuredImage?.node ? (
    <GatsbyImage
      image={getImage(post.featuredImage.node.localFile)}
      alt={post.title ?? 'Post Thumbnail'}
      loading="eager"
      className={`${TailwindTransitions.default[200]} group-hover:scale-105 ${imgClassName}`}
      objectFit="cover"
    />
  ) : (
    <StaticImage
      src="../../assets/placeholders/placeholder-720-480.png"
      alt="placeholder"
      width={300}
      className={`${TailwindTransitions.default[200]} group-hover:scale-105 ${imgClassName}`}
    />
  );

  return (
    <article
      className={`flex ${className}`}
      onMouseOver={() => setIsShadow(true)}
      onMouseOut={() => setIsShadow(false)}
    >
      <CardWrapper
        className="group flex-1 gap-1 bg-white hover:cursor-pointer"
        isShadow={isShadow}
        isBlur={true}
      >
        {/* For post image */}
        <UniversalLink
          to={post.uri}
          className="flex overflow-hidden rounded-tl-lg rounded-tr-lg"
        >
          {renderThumbnail}
        </UniversalLink>

        {/* Container post content */}
        <UniversalLink
          to={post.uri}
          className="group flex flex-1 flex-col justify-between gap-4 px-3 py-2"
        >
          {/* Post Title */}
          <h2
            dangerouslySetInnerHTML={{
              __html: post.title,
            }}
            className={`${TailwindTransitions.default[200]} line-clamp-3 block h-[72px] text-base font-bolder group-hover:text-primary`}
          />

          {/* Post Categories */}
          <PostCategories
            categories={post.categories?.nodes}
            textSize="text-xs"
          />

          {/* Post Tags */}
          <PostTags
            tags={post.tags?.nodes}
            isShowIcon={false}
            textSize="text-xs"
          />

          {/* Post Created Date */}
          <div className="flex flex-row gap-1">
            <TbCalendarEvent className="h-4 w-4 stroke-gray-400" />
            <p
              dangerouslySetInnerHTML={{__html: post.date}}
              className="text-xs leading-4 text-gray-400"
            />
          </div>
        </UniversalLink>
      </CardWrapper>
    </article>
  );
}

export default PostCard;

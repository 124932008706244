import React, {Fragment} from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import UniversalLink from '../_baseComponents/Link/UniversalLink';

/**
 * Render list of tags for a post
 * @param {Object} props
 * @param {Object} props.tags List tags of post
 * @param {Boolean} props.isShowIcon Show # icon if true
 * @param {String} props.textSize Tailwind text size
 * @returns
 */
const PostTags = ({
  tags,
  isShowIcon = true,
  textSize = 'text-sm',
  className = '',
}) => {
  return (
    <>
      {tags && tags.length >= 0 && (
        <div className={`flex h-4 w-full flex-row ${className}`}>
          {/* For '#' Icon */}
          {isShowIcon && (
            <div className="mr-2">
              <svg width="12" height="12" viewBox="0,0,448,512">
                <path d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z"></path>
              </svg>
            </div>
          )}

          {/* For Tag name */}
          {tags.map((tag) => (
            <Fragment key={tag.id}>
              <UniversalLink
                to={tag.uri}
                className={`${textSize} ${TailwindTransitions.default[300]} hover:text-link-hover`}
              >
                <span className="rounded-lg bg-gray-100 px-2 py-1">
                  {tag.name}
                </span>
              </UniversalLink>
              <span className="mr-1 last:mr-0"> </span>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default PostTags;
